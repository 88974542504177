'use client';
import { default as classNames, default as cx } from 'classnames';
import React, { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { HelpCircleIcon, InfoIcon } from '../icons/icons';

type Props = {
  className?: string;
  children: React.ReactNode;
  size?: number;
  tooltipType?: string;
  tooltipText?: string;
  position?: 'left' | 'middle' | 'right' | 'bottom' | 'mobileInputMiddle';
  textPosition?: 'text-left' | 'text-center';
  optionalClasses?: string;
  isHidden?: boolean;
  tooltipIcon?: ReactNode;
  tooltipClassname?: string;
};

export type TTooltip = {
  setForcedVisible: (x: boolean) => void;
};

const Tooltip = forwardRef<TTooltip, Props>(
  (
    {
      className,
      children,
      size = 18,
      tooltipType = false,
      position,
      textPosition,
      tooltipText = '',
      optionalClasses,
      isHidden = true,
      tooltipIcon,
      tooltipClassname = '',
    },
    ref
  ) => {
    const hasWindow = typeof window !== 'undefined';
    const tooltipRef = useRef<HTMLSpanElement>(null);
    const [forcedVisible, setForcedVisible] = useState(false);
    const [componentLeftPos, setComponentLeftPos] = useState(0);
    const [triangleLeftPos, setTriangleLeftPos] = useState(0);
    const [windowWidth, setWindowWidth] = useState(hasWindow ? window.innerWidth : null);

    useImperativeHandle(ref, () => ({
      setForcedVisible,
    }));

    useEffect(() => {
      const clickHandler = () => setForcedVisible(false);
      if (forcedVisible) window.addEventListener('click', clickHandler);
      return () => window.removeEventListener('click', clickHandler);
    }, [forcedVisible]);

    useEffect(() => {
      const rect = tooltipRef.current?.getBoundingClientRect();
      if (rect && windowWidth) {
        setComponentLeftPos(windowWidth - (windowWidth - rect.left) - 30);
        setTriangleLeftPos(rect.left - 30);
      }
    }, [tooltipRef, windowWidth]);

    const tooltipPosition = () => {
      switch (position) {
        case 'left':
          return `-left-3 ${textPosition ?? 'text-left'}`;
        case 'middle':
          return `left-1/2 transform -translate-x-1/2 ${textPosition ?? 'text-center'}`;
        case 'right':
          return `-right-3 ${textPosition ?? 'text-left'}`;
        case 'mobileInputMiddle':
          return '';
        case 'bottom':
          return 'md:-left-[284px] md:-bottom-[115px] -bottom-[130px] -left-[125px] text-left md:transform-none transform -translate-x-1/2';

        default:
          return `-right-3 ${textPosition ?? 'text-left'}`;
      }
    };

    const trianglePosition = () => {
      switch (position) {
        case 'left':
          return 'left-[13px]';
        case 'middle':
          return 'left-1/2 transform -translate-x-1/2';
        case 'right':
          return 'right-[13px]';
        case 'mobileInputMiddle':
          return '';
        case 'bottom':
          return 'transform -translate-y-1/2 rotate-180 right-[17px]';

        default:
          return 'right-[13px]';
      }
    };

    let icon = tooltipIcon; // Define icon variable
    if (tooltipType === 'info') {
      icon = <InfoIcon width={size} height={size} className="inline cursor-pointer" />;
    } else if (tooltipType === 'help') {
      icon = <HelpCircleIcon width={size} height={size} className="inline cursor-pointer" />;
    }

    return (
      <span ref={tooltipRef} className={cx('group relative', className)} data-testid="tooltip">
        {icon || <span data-testid="tooltipText">{tooltipText}</span>}
        <div
          className={classNames(
            tooltipClassname,
            'absolute z-10 rounded-lg bg-white px-4 py-3 shadow-tooltip',
            isHidden ? `${forcedVisible ? '' : 'hidden'} group-hover:block` : '',
            position == 'bottom' ? 'w-[317px]' : 'bottom-7 w-40 md:w-60',
            tooltipPosition(),
            optionalClasses
          )}
          style={{
            left: position === 'mobileInputMiddle' ? `-${componentLeftPos}px` : '',
          }}
        >
          {children}
          <div
            className={classNames(
              'tooltip-arrow-down absolute',
              trianglePosition(),
              position == 'bottom' ? '-top-[3px]' : 'top-full'
            )}
            style={{
              left: position === 'mobileInputMiddle' ? `${triangleLeftPos}px` : '',
            }}
          ></div>
        </div>
      </span>
    );
  }
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
